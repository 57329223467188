 <template>
  <div class="IndexListT com">
    <Title :name="category.title" :more="'/list?id='+category.id"></Title>
    <div class="_card" v-for="item,i in category.article" :key="i" v-show="i < 3" @click="goto(item.id)">
      <div class="_img" :style="{ backgroundImage: 'url('+item.image+')' }"></div>
      <div class="_info">
        <p> <a>{{item.title}}</a></p>
        <div class="_disc">
          {{item.describe}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../components/Title.vue";

export default {
  name: "IndexListS",
  components: { Title },

  props: {
    category: Object,
  },
  created() {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    goto(e) {
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push("/article?id=" + e + "&category=" + this.category.id);
    },
  },
};
</script>

<style lang="scss">
</style>
