  <template>
  <div class="index-list">
    <Title :name="category.title" :more="'/list?id='+category.id"></Title>
    <ul>
      <li v-for="item,i in category.article" :key="i" v-show="i < 8">
        <div class="_title">
          <a @click="goto(item.id)">{{item.title}}</a>
        </div>
        <div class="_time">
          {{item.created}} 
        </div>
      </li>  
    </ul>
  </div>
</template>

<script>
import Title from "../components/Title.vue";

export default {
  name: "home",
  components: { Title },
  props: {
    category: Object,
  },
  created() {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    goto(e){
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push('/article?id='+e+'&category='+this.category.id)
    }
  },
};
</script>

<style lang="scss">
</style>
