 <template>
  <div class="IndexNewSw card">
    <div class="card_box">
      <el-carousel height="356px" indicator-position="outside">
        <el-carousel-item v-for="item,i in category.slide" :key="i">
          <div class="sw-box" :style="{ backgroundImage: 'url('+item.slide+')' }" @click="goto(item.article)">
            <div class="sw-title">
              {{ item.title.slice(0, 28)}}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="card_box">
      <Title :name="category.title" :more="'/list?id='+category.id"></Title>
      <div class="index-new-list">
        <ul>
          <li v-for="item,i in category.article" :key="i"  v-show="i < 3" @click="goto(item.id)">
            <div class="new-list-title">
              {{ item.title.slice(0, 68)}}
            </div>
            <div class="new-list-info">
              {{item.describe}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../components/Title.vue";
export default {
  name: "IndexNewSw",
  components: { Title },
  props:{
    category:Object
  },
  created() {
  },
  data() {
    return {
      name: "扶贫要闻",
      more: "123",
      value:"全国脱贫攻坚总结表彰大会在京隆重举行 习近平向全国脱贫攻坚楷模荣誉称号获得者等颁奖并发表重要讲话",
    };
  },
  methods: {
    goto(e){
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push('/article?id='+e+'&category='+this.category.id)
    }
  },
};
</script>