<template>
  <div class="com" style="min-height: 1000px;">
    <div v-for="item,i in cards" :key="i">
      <IndexNewSw :category='item' v-if="i == 0"></IndexNewSw>
      <IndexListF :category='item' v-if="i == 1"></IndexListF>
      <div v-if="i == 1">
        <img style="width: 100%" src="https://cdn.d8gx.com/uPic/zxkz.jpg" alt="">
      </div>
      <div class="indx-line card" v-if="i == 2 || i == 3">
        <div class="indx-line-card" :class="i==2?'left':'right'">
          <List :category='item'></List>
        </div>
      </div>
      <IndexListT :category='item' v-if="i == 4"></IndexListT>
      <IndexListS :category='item' v-if="i == 5"></IndexListS>
    </div>
  </div>
</template>

<script>
import List from "../components/List.vue";
import IndexNewSw from "../components/IndexNewSw.vue";
import IndexListF from "../components/IndexListF.vue";
import IndexListT from "../components/IndexListT.vue";
import IndexListS from "../components/IndexListS.vue";

export default {
  name: "Index",
  components: { IndexNewSw, IndexListF,List, IndexListT, IndexListS },
  created() {
    this.index();
  },
  data() {
    return {
      value: false,
      cards: [],
    };
  },
  methods: {
    index() {
      this.$api.get({
        url: "index/navs",
        data:{type:'is_index'},
        success: (res) => {
          this.cards = res.data;
        },
      });
    },
  },
};
</script>