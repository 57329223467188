<template>
  <div class="IndexListS">
    <Title :name="category.title" :more="'/list?id='+category.id"></Title>
    <div class="row radius DaTing com" style="margin-bottom: 15px">
      <div class="demo" @mouseover="demoOnmouseover" @mouseout="demoOnmouseout">
        <table align="left" cellspace="0" border="0" cellpadding="0">
          <tr>
            <td class="demo1" valign="top">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <table cellSpacing="0" border="0" cellPadding="7">
                      <tbody>
                        <tr align="middle">
                          <td v-for="pics in groups" :key="pics.id">
                            <img :src="pics.image" style="cursor: pointer" @click="goto(pics.id)" alt="" />
                            <div class="p_title">{{pics.title}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td class="demo2" valign="top"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import Title from "../components/Title.vue";

export default {
  name: "DaTing",
  components: { Title },

  props:{
    category:Object
  },
  data() {
    return {
      groups: [],
      speed: 15,
      demo: null,
      demo1: null,
      demo2: null,
      MyMar: null,
    };
  },
  created() {
    this.getGroups();
  },
  methods: {
    async getGroups() {
      this.groups = this.category.article
      // let list = await this.$http.get("/api/listPhoto?type=adv");
      // this.groups = list;
    },
    goto(e) {
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push('/article?id='+e.id+'&category='+this.category.id)
    },
    demoOnmouseout() {
      this.MyMar = setInterval(this.Marquee, this.speed);
    },
    demoOnmouseover() {
      clearInterval(this.MyMar);
    },
    Marquee() {
      if (this.demo2.offsetWidth - this.demo.scrollLeft <= 0)
        this.demo.scrollLeft -= this.demo1.offsetWidth;
      else {
        this.demo.scrollLeft++;
      }
    },
    getDocument() {
      this.demo1 = document.getElementsByClassName("demo1")[0];
      this.demo = document.getElementsByClassName("demo")[0];
      this.demo2 = document.getElementsByClassName("demo2")[0];
      this.demo2.innerHTML = this.demo1.innerHTML;
      this.MyMar = setInterval(this.Marquee, this.speed);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getDocument();
    }, 2000);
  },
};
</script>

<style scoped>
.DaTing {
  height: 200px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.demo {
  overflow: hidden;
  height: 170px;
  width: 1132px;
  color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
img {
  height: 145px;
  width: 210px;
  object-fit: fill;
}
.p_title {
  width: 100%;
  height: 60px;
  line-height: 20px;
  color: #000;
}
</style>