 <template>
  <div class="IndexListF">
    <Title :name="category.title" :more="'/list?id='+category.id"></Title>
    <div class="list-f-box" v-for="item,i in category.child" :key="i" :class="i == 2?'noline':''">
      <div class="list-f-title">
        {{item.title}}
      </div> 
      <IndexListFCard :category='item'></IndexListFCard>
    </div>
  </div>
</template>

<script>
import IndexListFCard from "../components/IndexListFCard.vue";
import Title from "../components/Title.vue";

export default {
  name: "IndexListF",
  components: {IndexListFCard,Title},
  props:{
    category:Object
  },
  created() {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    goto(e){
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push('/article?id='+e+'&category='+this.category.id)
    }
  },
};
</script>