 <template>
 <div class="list-f-card-box">
  <div class="list-f-card" v-for="item,i in category.article" :key="i" v-show="i < 2">
    <div class="f-card-img" :style="{ backgroundImage: 'url('+item.image+')' }"></div>
    <div class="f-card-info">
      <div class="f-card-title">
        <a @click="goto(item.id)">{{item.title}}</a>
      </div>
      <div class="f-card-disc">
        {{item.describe}}
      </div>
      <div class="look">
        <a @click="goto(item.id)">[ 点击查看 ]</a>
      </div>
    </div>
  </div>
 </div>

</template>

<script>
export default {
  name: "home",
  components: {},
  created() {
    // this.getList();
  },
  props: {
    category: Object,
  },
  data() {
    return {
      cards: [],
    };
  },
  methods: {
    goto(e) {
      window.open('/article?id='+e+'&category='+this.category.id, '_blank');
      // this.$router.push("/article?id=" + e + "&category=" + this.category.id);
    },
    getList() {
      this.$api.get({
        url: "index/navs",
        data: { id: this.category },
        success: (res) => {
          this.cards = res.data;
        },
      });
    },
  },
};
</script>
